import axios from "axios";

export default class PedidosService {
  getFormasDePago(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "pedidos/formas-de-pago")
  }

  crearPedido(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "pedidos",data)
  }

  pedidosDeUnUsuario(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "pedidos/user")
  }

  pedidosAll(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "pedidos/admin")
  }

  changeStatus(data){
    return axios.put(process.env.VUE_APP_API_PUBLIC + "pedidos/state",data)
  }
}
