import axios from "axios";

export default class DireccionesService {
  createDireccion(data) {
    return axios.post(process.env.VUE_APP_API_PUBLIC + "direcciones", data);
  }

  getDireccionesForIdUser() {
    return axios.get(process.env.VUE_APP_API_PUBLIC + "direcciones");
  }

  getDireccionesForId(id) {
    return axios.get(process.env.VUE_APP_API_PUBLIC + "direcciones/" + id);
  }

  deleteDireccion(id) {
    const data = {
      id,
    };
    return axios.delete(process.env.VUE_APP_API_PUBLIC + "direcciones", {
      data,
    });
  }

  editDireccion(data) {
    return axios.put(process.env.VUE_APP_API_PUBLIC + "direcciones/edit", data);
  }

  verificarCobertura(data) {
    return axios.post(process.env.VUE_APP_API_PUBLIC + "verify-cover", data);
  }

  getAreaCobertura() {
    return [
      { lng: -74.16423797607422, lat: 4.724936386894007 },
      { lng: -74.05677795410156, lat: 4.594220579187978 },
      { lng: -74.0478515625, lat: 4.602776018958145 },
      { lng: -74.03411865234375, lat: 4.647604837557583 },
      { lng: -74.02587890625, lat: 4.672584546917322 },
      { lng: -74.01042938232422, lat: 4.7156981129306175 },
      { lng: -73.99944305419922, lat: 4.772836568611501 },
      { lng: -74.01214599609375, lat: 4.824154480910268 },
      { lng: -74.0317153930664, lat: 4.832364987977763 },
      { lng: -74.07669067382811, lat: 4.831338680030519 },
      { lng: -74.07669067382811, lat: 4.811838534173914 },
      { lng: -74.09008026123047, lat: 4.793022074300337 },
      { lng: -74.10655975341797, lat: 4.775573621185315 },
      { lng: -74.13436889648438, lat: 4.754703319862742 },
      { lng: -74.16423797607422, lat: 4.724936386894007 },
    ];
  }
}
